import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
  dsn: 'https://b113505a5ea94455b800b2b9012af15d@o411821.ingest.sentry.io/4504242710773760',
  enabled:
    window.location.hostname !== 'localhost' &&
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/logout',
  environment: window.ENV.APP_ENV,
  release: `caredash@${window.ENV.VERSION}`,
  maxValueLength: 1000,
  denyUrls: ['http://localhost:3000'],
  ignoreErrors: [
    'query() call aborted',
    'queryRoute() call aborted',
    'Failed to fetch',
    'Expected fetch controller',
    'NotAllowedError',
  ],
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.browserTracingIntegration({ useEffect, useLocation, useMatches }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      networkDetailAllowUrls: [/^https:\/\/dash\.mylaurelhealth\.com/],
    }),
    Sentry.feedbackIntegration({
      autoInject: false,
    }),
    Sentry.extraErrorDataIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  tracePropagationTargets: [/dash\.mylaurelhealth\.com/],

  // Capture Replay for 0% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // result in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.2,
});

function hydrate(): void {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
